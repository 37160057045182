<div class="my-5">
    <div class="container">
        <div class="p-2">
            <div class="row ps-4">
                <div class="col-md-8 container">
                    <div class="my-1">
                        <h5>Hey there, What's up?</h5>
                    </div>
                    <div class="text-justify pt-4">

                        <p>
                            BugFlu is a tech blog created with the sole purpose of explaining complex tech in a simple
                            and concise way thus creating value for budding developers out there.
                        </p>

                        <p>
                            Here you will find blogs on the latest tech, tutorials on programming languages,
                            explanations to data structure and algorithm concepts and questions, and much more... So
                            stay tuned!!
                        </p>

                    </div>

                    <div class="col-md-2 border-top border-dark pt-3 ">
                        <div class="mt-3 d-flex justify-content-start social-icon">
                            <a href="#">
                                <i class="bi bi-instagram i-20"></i>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=61567559143232" target="_blank">
                                <i class="bi bi-facebook i-20"></i>
                            </a>
                            <a href="#">
                                <i class="bi bi-linkedin i-20"></i>
                            </a>
                            <a href="#">
                                <i class="bi bi-twitter-x i-20"></i>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>