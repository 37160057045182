
<div class="card" >
    

    <div class="card-body border-bottom">

        <div class="gap-2 d-flex justify-content-between mt-4" >
            <h6 class="card-subtitle text-dark"><strong>Created By </strong>{{post.createdBy}}</h6>
            <h6 class="card-subtitle text-muted"><strong>Posted On </strong>{{post.createdDate | date:'dd MMMM, yyyy'}}</h6>
        </div>
       
        <a  routerLink="/post/title/{{post.slugTitle}}/view" style="text-decoration: none;" class="text-dark fw-bold"><h4 class="card-title mt-2" style="text-transform: uppercase;">{{post.title}}</h4></a>
        
        <div class="mt-3 d-flex justify-content-start">
            <a href="#" class="card-link text-strong text-danger" routerLink="/tag/view/{{tag.tagName}}" style="text-decoration: none; text-transform: lowercase;" *ngFor="let tag of post.tags">#{{tag.tagName}}</a>
        </div>

        <p class="text-justify mx-auto d-block" >{{post.summary}}</p>

    </div>
</div>