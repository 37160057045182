<div class="my-2">
    <div class="container">
        <div class="p-4">
            <div class="row">
                <h2 class="text-danger" style="text-transform: lowercase;">#{{tags.tagName}}</h2>
                <p class="text-dark">{{tags.description}}</p>
                <div class="border-top border-dark"></div>
              
                <h5 class="mt-3">Related Posts - </h5>

             
               
                
                <app-post-list-content *ngFor="let post of posts" [data]="post"></app-post-list-content>

            </div>
        </div>
    </div>
</div>
