<div class="card" id="viewContent">

    <div class="card-body">
 

        <h4 class="card-title mt-2" style="text-transform: uppercase;">{{data.title}}</h4>

        <div class="gap-2 d-flex justify-content-between mt-2">
            <h6 class="card-subtitle "><strong class="text-muted">Created By: </strong><a href="" style="text-decoration: none;">{{data.createdBy}}</a></h6>
            <h6 class="card-subtitle text-muted" style="float: right;"><strong>Published On </strong>{{ data.createdDate | date:'dd MMMM, yyyy'}}</h6>
        </div>

    
        <div class="mt-3 d-flex justify-content-start">
            <a href="#" class="card-link text-strong text-danger" routerLink="/tag/view/{{tag.tagName}}"
                style="text-decoration: none; text-transform: lowercase;"
                *ngFor="let tag of data.tags">#{{tag.tagName}}</a>
        </div>

        <div class="border-top mt-2">
            <div class="text-justify mx-auto d-block table table-hover mt-2" [innerHtml]="data.content | safeHtml"></div>
        </div>

    </div>
</div>