<form class="row g-3">
                    
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <label for="validationCustom01" class="form-label fw-bolder">Title</label>
                <p class="text-grey">Be specific and imagine your title for readers.</p>
                <input type="text" class="form-control" id="validationCustom01" placeholder="Enter Title" required>
                <div class="valid-feedback">
                Looks good!
                </div>
        </div>
        </div>
    </div>

    <div class="col-md-12 ">
        <div class="card">
            <div class="card-body">
                <label for="validationCustom04" class="form-label fw-bolder">Select Category</label>
                <p class="text-grey">Select appropriate category what are posting </p>
                <select class="form-select" id="validationCustom04" required>
                <option selected disabled value="">Choose...</option>
                <option>Computer Science</option>
                </select>
                <div class="invalid-feedback">
                Please select a valid state.
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <label for="tagValidation" class="form-label fw-bolder card-title">Select Tags</label>
                <p class="card-text">Add up to 5 tags to describe what are you posting about. Start typing to see suggestions.</p>
                <ng-multiselect-dropdown
                    [placeholder]="'select Tag'"
                    [settings]="dropdownSettings"
                    [data]="dropdownList"
                    (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)"
                    >
                </ng-multiselect-dropdown>
            </div>
        </div>
  
    </div>

    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <label for="tagValidation" class="form-label fw-bolder card-title">Content</label>
                <p class="card-text"> Write content about something</p>
                <editor apiKey="jug949l49wduq4fl7p7qzvwktc5d9nv442tysgqqrc1jh0sb"
                    [init]="{
                        plugins: 'anchor autolink  harmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                        tinycomments_mode: 'embedded',
                        tinycomments_author: 'Author name',
                        mergetags_list: [
                        { value: 'First.Name', title: 'First Name' },
                        { value: 'Email', title: 'Email' },
                        ],
                    }"
                    >
                </editor>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        An “agree to terms and conditions” checkbox is a method of protecting your business by requiring that users acknowledge the rules they must abide by when using your services.
                    </label>
                </div>

                <div class="ms-auto d-flex justify-content-end p-2">
                    <button type="button" class="btn btn-square btn-primary me-2 " >Save</button>
                    <button type="button" class="btn btn-square btn-success" >Submit To Review</button>
                </div>
            </div>
        </div>
    </div>

    <div class="ms-auto d-flex justify-content-start p-2">
        <button type="button" class="btn btn-outline-danger me-2 " >Discard Post</button>
    </div>
    
</form>