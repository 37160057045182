    
<div class="my-3">
    <div class="container">
        <div class="p-4">
            <div class="row">
                <div class="col-md-10">
                    <div class="my-2">
                        <h3>Update your post</h3>
                    </div>
                    <app-post-editor></app-post-editor>
                </div>
            </div>
        </div>
    </div>
</div>
