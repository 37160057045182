<div class="my-3">
    <div class="container-fluid">
        <div class="p-4">
            <div class="row">

                <div class="col-md-9 mt-2">
                    <app-view-post-content [data]="this.postData" *ngIf="this.postData != null"></app-view-post-content>
                    <app-error *ngIf="this.postData == null" [error]="apiError"></app-error>
                </div>

                <div class="col-md-3 mt-2">

                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Tags</h4>
                            <a routerLink="/tag/view/{{tag.tagName}}"
                             *ngFor="let tag of postData.tags" 
                             style="text-decoration: none; font-style: italic;text-transform: lowercase;" 
                             class="text-danger"> #{{tag.tagName}}
                            </a>
                        </div>
                    </div>

                    <div class="card mt-3">
                        <div class="card-body">
                            <!-- <h4 class="card-title">

                            </h4> -->
                            <ng-adsense
                            [adClient]="'ca-pub-8798159583506964'"
                            [display]="'inline-block'"
                          ></ng-adsense>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>